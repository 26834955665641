import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 30px;
  width: 100%;
  max-width: 1200px;

  align-items: stretch;
  @media screen and (min-width: 600px) {
    padding: 10px 70px;
  }
  @media screen and (min-width: 900px) {
    padding: 10px 90px;
  }
  @media screen and (min-width: 1200px) {
    padding: 10px 135px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  .projectCard {
    max-width: 900px;
  }
  .projectCard:last-of-type {
    margin-bottom: 100px;
  }

  section {
    padding-left: 5px;
    transition: transform 0.7s;
  }

  section:hover {
    transform: translateY(-10px);

    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
  }

  h1 {
    margin: 60px 0;
    border-radius: 10px;
    max-width: 1000px;
    text-align: left;
    font-family: 'Arbutus Slab', serif;
    margin-bottom: 0px;
    color: #fff;
  }
  h2,
  h3,
  h4 {
    margin-top: 80px;
    max-width: 1000px;
    text-align: left;
    font-family: 'Libre Franklin', sans-serif;
    color: #fff;

    span {
      padding: 2px 4px 2px;
      border-radius: 10px;
      text-align: center;
      font-family: 'Arbutus Slab', serif;
      margin-bottom: 30px;
      color: #312e38;
      background: #ff9000;
      line-height: 40px;
    }

    a {
      color: #fff;
      text-decoration: none;
    }
  }
  p {
    margin: 14px 0;
    font-size: 18px;
    max-width: 1000px;
    text-align: left;
    font-family: 'Libre Franklin', sans-serif;
    color: #fff;
  }
  a {
    color: #000;
    font-weight: bold;
    text-decoration: none;
  }
`;
