import React from 'react';
import { Link } from 'react-router-dom';

import Header from '../../components/Header';
import { Container, Content, Background } from './styles';

const HomePage: React.FC = () => (
  <>
    <Header />
    <Container>
      <Content>
        <h1>I&apos;m Marcelo Matos</h1>
        <h3>I&apos;m a Software Developer based in Winnipeg, Canada</h3>
        <Link to="/portfolio">Portfolio</Link>
      </Content>
      <Background />
    </Container>
  </>
);

export default HomePage;
