import styled from 'styled-components';

export const Container = styled.div`
  background: #64adca;
  padding: 10px 0;
  transition: background 0.2s;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  left: 0;
  z-index: 100;

  &:hover {
    background: #fff;

    a {
      color: #000;
    }
  }

  header {
    margin: 10px auto;
    padding: 10px 20px;
    height: auto;
    nav {
      max-width: 1300px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      a {
        color: #fff;
        text-decoration: none;
        font-size: 16px;
        transition: color 0.2s;

        & + a {
          margin-left: 32px;
        }

        &:hover {
          color: #64adca;
        }
      }
    }
  }
`;
