import React from 'react';
import ReactPlayer from 'react-player';
import Header from '../../components/Header';
import { Container, Content } from './styles';

const PortfolioPage: React.FC = () => (
  <>
    <Header />
    <Container>
      <Content>
        <h1>Recent Projects</h1>
        <div className="projectCard">
          <section>
            <h3>
              <span>GoBarber Mobile - Work in progress*</span>
              <br />
              Backend: NodeJS
              <br />
              App: React Native + Typescript
            </h3>
            <p>
              This is a project that will allow barbershops and hairstylists to
              offer their services, while customers can easily schedule an
              appointment with a wide range of professionals to choose from.
            </p>
            <p>
              * Current status: <br />
              - Backend API is functional and the SQL database used is Postgres
              in a Docker Container. <br />- Mobile App has the Sign in and Sign
              up functionality working, as well navigation between pages,
              including access to restricted areas only to verified users. The
              input validations, including some improvements on the UX, have
              also been implemented.
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/C9z-wKEBUIc"
            controls
            playbackRate={2}
            width="896px"
            height="504px"
          />
        </div>
        <div className="projectCard">
          <section>
            <h3>
              <span>GoBarber Web - Work in progress*</span>
              <br />
              Backend: NodeJS
              <br />
              Frontend: ReactJS + Typescript
            </h3>
            <p>
              This is a project that will allow barbershops and hairstylists to
              offer their services, while customers can easily schedule an
              appointment with a wide range of professionals to choose from.
            </p>
            <p>
              * Current status: <br />
              - Backend API is functional and the SQL database used is Postgres
              in a Docker Container. <br />- Frontend has the Sign in and Sign
              up functionality working, as well navigation between pages,
              including access to restricted areas only to verified users. The
              input validations, including tooltips and Toast, have also been
              implemented.
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/Y7tlXE4uqV4"
            controls
            playbackRate={1.5}
            width="896px"
            height="504px"
          />
        </div>
        <div className="projectCard">
          <section>
            <h3>
              <span>Ecoleta</span>
              <br />
              Backend: NodeJS
              <br />
              Frontend: ReactJS + Typescript
              <br />
              Mobile: React Native
            </h3>
            <p>
              Ecoleta is a project developed based on Environment Week 2020. The
              solution aims to help people find Recycling Depots in an efficient
              way.
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/VzMMzmtLLt0"
            controls
            playbackRate={2}
            width="896px"
            height="504px"
          />
        </div>

        <div className="projectCard">
          <section>
            <h3>
              <span>Git Contacts</span>
              <br />
              Mobile application: Android (Java)
            </h3>
            <p>
              This app was developed as a personal project to learn more about
              native android development using Java. This project includes
              authentication using Firebase and Github as the provider.
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/cqfSOPIfg_s"
            controls
            playbackRate={1.5}
            width="896px"
            height="504px"
          />
        </div>

        <div className="projectCard">
          <section>
            <h3>
              <span>GoFinances</span>
              <br />
              Backend: NodeJS
              <br />
              Frontend: ReactJS + Typescript
            </h3>
            <p>
              An application that allows users to visualize their income and
              outcomes transactions, importing the data from a CSV file, and
              using a drag and drop zone.
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/luai0p0y2zE"
            controls
            playbackRate={2}
            width="896px"
            height="504px"
          />
        </div>

        <div className="projectCard">
          <section>
            <h3>
              <span>Git Explorer</span>
              <br />
              Frontend: ReactJS + Typescript
            </h3>
            <p>
              This application connects to the Github API and allows the user to
              follow Github repositories that they might be interested in. The
              app shows some details and the list of open issues.This project
              was deployed to Netlify.
            </p>
            <p>
              <a
                href="https://elastic-mclean-88069e.netlify.app/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                &gt;&gt; Visit the project &lt;&lt;
              </a>
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/zuo-mJscZhY"
            controls
            playbackRate={2}
            width="896px"
            height="504px"
          />
        </div>

        <div className="projectCard">
          <section>
            <h3>
              <span>Know your city</span>
              <br />
              Mobile application: Android (Java)
            </h3>
            <p>
              This was an academic project, all data and images belong to the
              Tourism Winnipeg department. The idea here is to use gamification
              to bring more traffic and visibility to the Tourism Winnipeg
              website using this mobile app.
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/bsEWE8eoulA"
            controls
            playbackRate={2}
            width="896px"
            height="504px"
          />
        </div>

        <div className="projectCard">
          <section>
            <h3>
              <span>Red River Bay</span>
              <br />
              Backend and Frontend: Ruby on Rails
              <br />
            </h3>
            <p>
              This was an academic project, and the goal was to create a fully
              functional e-commerce website, including the frontend and backend.
              This project was created using Ruby on Rails, with Active Admin
              and Active Storage, so the images are stored at Google Cloud. This
              project was deployed to Heroku.
            </p>
            <p>
              <a
                href="https://acryl-draft.herokuapp.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {' '}
                &gt;&gt; Visit the project &lt;&lt;
              </a>
              <br />
              *Sometimes Heroku can be very slow to load the page...
            </p>
          </section>
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/6hxNVaJaies"
            controls
            playbackRate={2}
            width="896px"
            height="504px"
          />
        </div>
      </Content>
    </Container>
  </>
);

export default PortfolioPage;
