import React from 'react';

import Header from '../../components/Header';
import { Container, Content, Background } from './styles';

const AboutPage: React.FC = () => (
  <>
    <Header />
    <Container>
      <Content>
        <h1>
          Creative and collaborative Software Developer with additional
          experience in Business Intelligence
        </h1>

        <h2>
          Throughout the progression of my career, I have developed a highly
          refined skill set centered in synthesizing complex concepts,
          transforming abstract ideas on clear, concise, and engaging Documents
          and Reports. Now I&apos;m transferring all this knowledge to my
          Applications.
        </h2>
        <h3>
          Knowledge of{' '}
          <span>
            Javascript, Typescript, React, NodeJS, React Native, Ruby on Rails,
            C# and Android(Java),
          </span>{' '}
          with 5+ years of experience as a Business Intelligence Analyst.
        </h3>
        <h3>
          Academic history:
          <ul>
            <li>Business Information Technology College Diploma</li>
            <li>MBA in Business</li>
            <li>Bachelor&apos;s Degree in Business</li>
          </ul>
        </h3>
      </Content>
      <Background />
    </Container>
  </>
);

export default AboutPage;
