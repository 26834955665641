import styled from 'styled-components';

import WinnipegImg from '../../assets/Winnipeg_optimized.jpg';

export const Background = styled.div`
  flex: 1;
  max-width: 500px;
  background: url(${WinnipegImg}) no-repeat center;
  background-size: cover;
  border-radius: 10px;
`;

export const Container = styled.div`
  height: 90vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 700px;

  h1 {
    padding: 10px;
    margin: 80px 0;
    border-radius: 10px;
    max-width: 700px;
    text-align: center;
    font-family: 'Libre Franklin', sans-serif;
    margin-bottom: 24px;
    color: #312e38;
    background: #ff9000;
  }
  h2,
  h3,
  h4 {
    margin: 80px 0;
    max-width: 700px;
    text-align: center;
    font-family: 'Libre Franklin', sans-serif;
    margin-bottom: 24px;
    margin-left: 20px;
    color: #fff;

    span {
      color: #f5b622;
    }

    ul {
      text-align: left;
      list-style: none;

      li {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        align-items: flex-start;
        justify-content: left;
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      align-items: center;
    }
  }
  svg {
    display: flex;
    align-self: center;
    margin-right: 5px;
  }

  h5 {
    position: absolute;
    bottom: 0;
    text-align: right;
    height: 20px;
  }
`;
