import React from 'react';
import { GoMarkGithub, GoMail } from 'react-icons/go';
import { FaLinkedin } from 'react-icons/fa';
import Header from '../../components/Header';
import { Container, Content, Background } from './styles';

const ContactPage: React.FC = () => (
  <>
    <Header />
    <Container>
      <Content>
        <h1>Marcelo Matos</h1>
        <h3>
          <ul>
            <li>
              <GoMail />
              marcelomatos.dev@gmail.com
            </li>
            <li>
              <FaLinkedin />
              <a href="https://www.linkedin.com/in/marcelo-matos-software-developer/">
                LinkedIn
              </a>
            </li>
            <li>
              <GoMarkGithub />
              <a href="https://github.com/marcelomatosdev">GitHub</a>
            </li>
          </ul>
        </h3>
      </Content>
      <Background />
    </Container>
  </>
);

export default ContactPage;
