import styled from 'styled-components';

import FullstackIconJS from '../../assets/full-stack-javascript.svg';

export const Background = styled.div`
  flex: 1;
  max-width: 400px;
  background: url(${FullstackIconJS}) no-repeat center;
  background-size: cover;
  border-radius: 10px;
`;

export const Container = styled.div`
  height: 90vh;
  display: flex;
  padding: 10px 30px;
  align-items: stretch;
  @media screen and (min-width: 600px) {
    padding: 10px 70px;
  }
  @media screen and (min-width: 900px) {
    padding: 10px 90px;
  }
  @media screen and (min-width: 1200px) {
    padding: 10px 135px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  max-width: 700px;

  h1 {
    margin: 60px 0;
    max-width: 700px;
    text-align: left;
    font-family: 'Arbutus Slab', serif;
    margin-bottom: 24px;
    color: #fff;
  }
  h2 {
    margin-top: 60px;
    max-width: 700px;
    text-align: left;
    font-family: 'Libre Franklin', sans-serif;
    color: #fff;
  }
  h3 {
    margin-top: 60px;
    max-width: 700px;
    text-align: left;
    font-family: 'Libre Franklin', sans-serif;
    color: #fff;
    font-size: 24px;

    span {
      font-weight: bolder;
      color: #f5b622;
    }

    ul {
      text-align: left;
    }

    & + h3 {
      margin: 50px 0 40px 20px;
    }
  }
`;
