import styled from 'styled-components';
import { shade } from 'polished';
import marceloBackgroundImg from '../../assets/Marcelo_optimized.png';

export const Container = styled.div`
  height: 90vh;
  display: flex;
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
  max-width: 700px;

  h1 {
    font-family: 'Arbutus Slab', serif;
    margin-bottom: 24px;
    color: #fff;
  }
  h3 {
    width: 340px;
    text-align: center;
    font-family: 'Arbutus Slab', serif;
    margin-bottom: 24px;
    color: #fff;
  }

  a {
    max-width: 340px;
    text-align: center;
    text-decoration: none;
    background: #ff9000;
    height: 56px;
    border-radius: 10px;
    border: 0;
    padding: 16px 16px;
    color: #312e38;
    width: 100%;
    font-weight: 500;
    margin-top: 16px;
    transition: background-color 0.2s;

    &:hover {
      background: ${shade(0.2, '#ff9000')};
    }
  }
`;
export const Background = styled.div`
  flex: 1;
  max-width: 500px;
  background: url(${marceloBackgroundImg}) no-repeat center;
  background-size: cover;
  border-radius: 10px;
`;
